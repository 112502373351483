
import { defineComponent } from "vue";
import Header from "@/components/ssw/Header/Header.vue";

export default defineComponent({
  name: "PageNotFoundView",
  components: {
    Header,
  },
});
