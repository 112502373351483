import { defineComponent } from 'vue';
import config from '@/config/app';
export default defineComponent({
  name: 'downloads',
  data() {
    return {
      downloadsList: config.downloads,
    }
  },
  methods: {
  },
});