const config = {
  sswWheelBrands: {
    ssw: [
      {
        id: "1",
        name: "15 Inch",
        image: "ssw/15-Inch.webp",
        tyre_image: null,
        typeCount: 7,
        tyreType: [
          {
            id: "1",
            name: "E101-15",
            title: "E101",
            wheels: [
              {
                title: "E101E04",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/15/tyres/E101 15x65 BK_1.webp",
                    alt: "SSW E101",
                  },
                  {
                    image: "ssw/15/tyres/E101 15x65 BK_2.webp",
                    alt: "SSW E101",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "15 inch", "Gloss Black", "Size 15 x 6.5", "PCD 5 x 100", "Offset 38", "Centre bore 57.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "2",
            name: "S223-15",
            title: "S223",
            wheels: [
              {
                title: "S223E06",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/15/tyres/S223 BKM 15x65-1.webp",
                    alt: "SSW S223",
                  },
                  {
                    image: "ssw/15/tyres/S223 BKM 15x65_1-1.webp",
                    alt: "SSW S223",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "15 inch", "Black Matt", "Size 15 x 6.5", "PCD 5 x 100", "Offset 38", "Centre bore 73.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "3",
            name: "S242-15",
            title: "S242",
            wheels: [
              {
                title: "S242E08",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/15/tyres/S242 15x7_FP_BK_GCBK-01.webp",
                    alt: "SSW S242",
                  },
                  {
                    image: "ssw/15/tyres/S242 15x7_FP_BK_GCBK-02.webp",
                    alt: "SSW S242",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "15 inch", "Face Polish/ Black/ Gloss Clear Black", "Size 15 x 7", "PCD 8 x 100/ 108", "Offset 35", "Centre bore 73.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "4",
            name: "S254-15",
            title: "S254",
            wheels: [
              {
                title: "S254E01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/15/tyres/S254_15x7_BKM_C074_01.webp",
                    alt: "SSW S254",
                  },
                  {
                    image: "ssw/15/tyres/S254_15x7_BKM_C074_02.webp",
                    alt: "SSW S254",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "15 inch", "Black Matt", "Size 15 x 7", "PCD 4 x 100", "Offset 35", "Centre bore 73.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "5",
            name: "S271-15",
            title: "S271",
            wheels: [
              {
                title: "S271E02",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/15/tyres/S271 15x7_FP_BK_8H-01.webp",
                    alt: "SSW S271",
                  },
                  {
                    image: "ssw/15/tyres/S271 15x7_FP_BK_8H-02.webp",
                    alt: "SSW S271",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "15 inch", "Face Polish/ Black", "Size 15 x 7", "PCD 8 x 100/ 108", "Offset 38", "Centre bore 73.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "6",
            name: "S289-15",
            title: "S289",
            wheels: [
              {
                title: "S289E06",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/15/tyres/S289 15x7_FP_BK_5H-01.webp",
                    alt: "SSW S289",
                  },
                  {
                    image: "ssw/15/tyres/S289 15x7_FP_BK_5H-02.webp",
                    alt: "SSW S289",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "15 inch", "Face Polish/ Black", "Size 15 x 7", "PCD 5 x 100", "Offset 35", "Centre bore 57.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "7",
            name: "S385-15",
            title: "S385",
            wheels: [
              {
                title: "S385E01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/15/tyres/S385 15x7_BK.webp",
                    alt: "SSW S385",
                  },
                  {
                    image: "ssw/15/tyres/S385 15x7_BK2.webp",
                    alt: "SSW S385",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "15 inch", "Gloss Black", "Size 15 x 7", "PCD 4 x 100", "Offset 35", "Centre bore 73.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
        ],
      },
      {
        id: "2",
        name: "16 Inch",
        image: "ssw/16-Inch.webp",
        tyre_image: null,
        typeCount: 3,
        tyreType: [
          {
            id: "1",
            name: "S126-16",
            title: "S126",
            wheels: [
              {
                title: "S126F01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/16/tyres/S126-BKM-02-17x7_1.webp",
                    alt: "SSW S126",
                  },
                  {
                    image: "ssw/16/tyres/S126-BKM-02-17x7_2.webp",
                    alt: "SSW S126",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "16 inch", "Black Matt", "Size 16 x 6.5", "PCD 5 x 160", "Offset 42"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S126F02",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/16/tyres/S126-FP-BK-16x65_1.webp",
                    alt: "SSW S126",
                  },
                  {
                    image: "ssw/16/tyres/S126-FP-BK-16x65_2.webp",
                    alt: "SSW S126",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "16 inch", "Face Polish Black", "Size 16 x 6.5", "PCD 5 x 160", "Offset 42"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "2",
            name: "S297-16",
            title: "S297",
            wheels: [
              {
                title: "S297F01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/16/tyres/S297-16x8_GMD_5H_001.webp",
                    alt: "SSW S297",
                  },
                  {
                    image: "ssw/16/tyres/S297-16x8_GMD_5H_002.webp",
                    alt: "SSW S297",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "16 inch", "Gun Metal Dark", "Size 16 x 8", "PCD 5 x 139.7", "Offset 0"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "3",
            name: "S401-16",
            title: "S401",
            wheels: [
              {
                title: "S401F01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/16/tyres/S401-16x8_GMD_6H_001.webp",
                    alt: "SSW S401",
                  },
                  {
                    image: "ssw/16/tyres/S401-16x8_GMD_6H_002.webp",
                    alt: "SSW S401",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "16 inch", "Gun Metal Dark", "Size 16 x 8", "PCD 5 x 139.7", "Offset 0"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
        ],
      },
      {
        id: "3",
        name: "17 Inch",
        image: "ssw/17-Inch.webp",
        tyre_image: null,
        typeCount: 8,
        tyreType: [
          {
            id: "1",
            name: "S205-17",
            title: "S205",
            wheels: [
              {
                title: "S205G01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/17/tyres/S205 17x8_GMM.webp",
                    alt: "SSW S205",
                  },
                  {
                    image: "ssw/17/tyres/S205 17x8_GMM2.webp",
                    alt: "SSW S205",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "17 inch", "Gun Metal Matt", "Size 17 x 8", "PCD 5 x 100", "Offset 35", "Centre bore 57.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "2",
            name: "S254-17",
            title: "S254",
            wheels: [
              {
                title: "S254G01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/17/tyres/S254_17x8_GMM_C083-01.webp",
                    alt: "SSW S254",
                  },
                  {
                    image: "ssw/17/tyres/S254_17x8_GMM_C083-02.webp",
                    alt: "SSW S254",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "17 inch", "Gun Metal Matt", "Size 17 x 8", "PCD 5 x 100", "Offset 35", "Centre bore 57.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "3",
            name: "S267-17",
            title: "S267",
            wheels: [
              {
                title: "S267G01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/17/tyres/S267_17x75_BKM_C083-01.webp",
                    alt: "SSW S267",
                  },
                  {
                    image: "ssw/17/tyres/S267_17x75_BKM_C083-02.webp",
                    alt: "SSW S267",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "17 inch", "Black Matt", "Size 17 x 7.5", "PCD 5 x 114.3", "Offset 35", "Centre bore 73.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "4",
            name: "S308-17",
            title: "S308",
            wheels: [
              {
                title: "S308G01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/17/tyres/S308 17x8 BKM.webp",
                    alt: "SSW S308",
                  },
                  {
                    image: "ssw/17/tyres/S308 17x8 BKM_2.webp",
                    alt: "SSW S308",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "17 inch", "Black Matt", "Size 17 x 8", "PCD 5 x 100", "Offset 35", "Centre bore 57.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "5",
            name: "S348-17",
            title: "S348",
            wheels: [
              {
                title: "S348G01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/17/tyres/S348 17x8 BKM(4H)_1.webp",
                    alt: "SSW S348",
                  },
                  {
                    image: "ssw/17/tyres/S348 17x8 BKM(4H)_2.webp",
                    alt: "SSW S348",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "17 inch", "Black Matt", "Size 17 x 8", "PCD 4 x 100", "Offset 30", "Centre bore 73.1"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S348G02",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/17/tyres/S348 17x8 BKM(4H)_1.webp",
                    alt: "SSW S348",
                  },
                  {
                    image: "ssw/17/tyres/S348 17x8 BKM(4H)_2.webp",
                    alt: "SSW S348",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "17 inch", "Black Matt", "Size 17 x 8", "PCD 4 x 108", "Offset 30", "Centre bore 73.1"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S348G03",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/17/tyres/S348 G0317x8_BKM_5H-01.webp",
                    alt: "SSW S348",
                  },
                  {
                    image: "ssw/17/tyres/S348 G0317x8_BKM_5H-02.webp",
                    alt: "SSW S348",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "17 inch", "Black Matt", "Size 17 x 8", "PCD 5 x 114.3", "Offset 35", "Centre bore 73.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "6",
            name: "S385-17",
            title: "S385",
            wheels: [
              {
                title: "S385G01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/17/tyres/S385 17x8_FP_BK_GCBK_5H_001.webp",
                    alt: "SSW S385",
                  },
                  {
                    image: "ssw/17/tyres/S385 17x8_FP_BK_GCBK_5H_002.webp",
                    alt: "SSW S385",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "17 inch", "Face Polish/ Black/ Gloss Clear Black", "Size 17 x 8", "PCD 5 x 100", "Offset 38", "Centre bore 57.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "7",
            name: "S407-17",
            title: "S407",
            wheels: [
              {
                title: "S407G01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/17/tyres/S407-17x75_FP_BK_5H_and_C031-1_01.webp",
                    alt: "SSW S407",
                  },
                  // {
                  //   image: "ssw/17/tyres/S407-17x75_FP_BK_5H_and_C031-1_02.webp",
                  //   alt: "SSW S407",
                  // },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "17 inch", "Face Polish Black", "Size 17 x 7.5", "PCD 5 x 120", "Offset 45"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "8",
            name: "S408-17",
            title: "S408",
            wheels: [
              {
                title: "S408G01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/17/tyres/S408-17x75_FP_BK_5H_and_C031-1_01.webp",
                    alt: "SSW S408",
                  },
                  // {
                  //   image: "ssw/17/tyres/S408-17x75_FP_BK_5H_and_C031-1_02.webp",
                  //   alt: "SSW S408",
                  // },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "17 inch", "Face Polish Black", "Size 17 x 7.5", "PCD 5 x 120", "Offset 45"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
        ],
      },
      {
        id: "4",
        name: "18 Inch",
        image: "ssw/18-Inch.webp",
        tyre_image: null,
        typeCount: 8,
        tyreType: [
          {
            id: "1",
            name: "S254-18",
            title: "S254",
            wheels: [
              {
                title: "S254H01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/18/tyres/S254 18x8.5_BKM_5H_C083-01.webp",
                    alt: "SSW S254",
                  },
                  {
                    image: "ssw/18/tyres/S254 18x8.5_BKM_5H_C083-02.webp",
                    alt: "SSW S254",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "18 inch", "Black Matt", "Size 18 x 8.5", "PCD 5 x 112", "Offset 38", "Centre bore 73.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "2",
            name: "S279-18",
            title: "S279",
            wheels: [
              {
                title: "S279H01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/18/tyres/S279-18x8-FAP_BK.webp",
                    alt: "SSW S279",
                  },
                  {
                    image: "ssw/18/tyres/S279-18x8-FAP_BK2.webp",
                    alt: "SSW S279",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "18 inch", "Face Polish Black", "Size 18 x 8", "PCD 5 x 114.3", "Offset 38"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "3",
            name: "S308-18",
            title: "S308",
            wheels: [
              {
                title: "S308H01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/18/tyres/S308-18x9_Si_1.webp",
                    alt: "SSW S308",
                  },
                  {
                    image: "ssw/18/tyres/S308-18x9_Si_2.webp",
                    alt: "SSW S308",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "18 inch", "Silver", "Size 18 x 8", "PCD 5 x 108", "Offset 35"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "4",
            name: "S333-18",
            title: "S333",
            wheels: [
              {
                title: "S333H01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/18/tyres/S333-18x85_GMD_001.webp",
                    alt: "SSW S333",
                  },
                  {
                    image: "ssw/18/tyres/S333-18x85_GMD_002.webp",
                    alt: "SSW S333",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "18 inch", "Gun Metal Matt", "Size 18 x 8.5", "PCD 5 x 100", "Offset 35"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "5",
            name: "S343-18",
            title: "S343",
            wheels: [
              {
                title: "S343H01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/18/tyres/S343-18x85_GMM_LP.webp",
                    alt: "SSW S343",
                  },
                  {
                    image: "ssw/18/tyres/S343-18x85_GMM_LP2.webp",
                    alt: "SSW S343",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "18 inch", "Gun Metal Matt /Lip Polish", "Size 18 x 8.5", "PCD 5 x 100", "Offset 40"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "6",
            name: "S366-18",
            title: "S366",
            wheels: [
              {
                title: "S366H01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/18/tyres/S366 18x8_GMM_01.webp",
                    alt: "SSW S366",
                  },
                  {
                    image: "ssw/18/tyres/S366 18x8_GMM_5H2.webp",
                    alt: "SSW S366",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "18 inch", "Gun Metal Matt", "Size 18 x 8", "PCD 5 x 112", "Offset 38", "Centre bore 73.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "7",
            name: "S386-18",
            title: "S386",
            wheels: [
              {
                title: "S386H01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/18/tyres/S386-18x9_FP_BK_GCBK_C095-1.webp",
                    alt: "SSW S386",
                  },
                  {
                    image: "ssw/18/tyres/S386-18x9_FP_BK_GCBK_C095-2.webp",
                    alt: "SSW S386",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "18 inch", "Face Polish /Black/Gloss Clear Bronze", "Size 18 x 9", "PCD 6 x 114.3", "Offset 20"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "8",
            name: "S413-18",
            title: "S413",
            wheels: [
              {
                title: "S413H01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/18/tyres/S413-19x95_SSM_5H-01.webp",
                    alt: "SSW S413",
                  },
                  {
                    image: "ssw/18/tyres/S413-19x95_SSM_5H-02.webp",
                    alt: "SSW S413",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "18 inch", "Silver Matt", "Size 18 x 8.5", "PCD 5 x 100", "Offset 35"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
        ],
      },
      {
        id: "5",
        name: "19 Inch",
        image: "ssw/19-Inch.webp",
        tyre_image: null,
        typeCount: 2,
        tyreType: [
          {
            id: "1",
            name: "S348-19",
            title: "S348",
            wheels: [
              {
                title: "S348I01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/19/tyres/S348 I01-I03-I04 19x85_BK_1.webp",
                    alt: "SSW S348",
                  },
                  {
                    image: "ssw/19/tyres/S348I01-I03-I04 19x85_BKM_2.webp",
                    alt: "SSW S348",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "19 inch", "Black Matt", "Size 19 x 8.5", "PCD 5 x 112", "Offset 40", "Centre bore 73.1"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S348I02",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/19/tyres/S348 19x85_GMM_C083-01.webp",
                    alt: "SSW S348",
                  },
                  {
                    image: "ssw/19/tyres/S348 19x85_GMM_C083-02.webp",
                    alt: "SSW S348",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "19 inch", "Gun Metal Matt", "Size 19 x 8.5", "PCD 5 x 112", "Offset 40", "Centre bore 73.1"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S348I03",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/19/tyres/S348 I01-I03-I04 19x85_BK_1.webp",
                    alt: "SSW S348",
                  },
                  {
                    image: "ssw/19/tyres/S348I01-I03-I04 19x85_BKM_2.webp",
                    alt: "SSW S348",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "19 inch", "Black Matt", "Size 19 x 8.5", "PCD 5 x 120", "Offset 35", "Centre bore 72.6"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S348I04",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/19/tyres/S348 I01-I03-I04 19x85_BK_1.webp",
                    alt: "SSW S348",
                  },
                  {
                    image: "ssw/19/tyres/S348I01-I03-I04 19x85_BKM_2.webp",
                    alt: "SSW S348",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "19 inch", "Black Matt", "Size 19 x 9.5", "PCD 5 x 120", "Offset 42", "Centre bore 72.6"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "2",
            name: "S413-19",
            title: "S413",
            wheels: [
              {
                title: "S413I01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/19/tyres/S413-19x95_SSM_5H-01.webp",
                    alt: "SSW S413",
                  },
                  {
                    image: "ssw/19/tyres/S413-19x95_SSM_5H-02.webp",
                    alt: "SSW S413",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "19 inch", "Silver Matt", "Size 19 x 8.5", "PCD 5 x 100", "Offset 35"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S413I02",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/19/tyres/S413-19x95_BKM_5H-01.webp",
                    alt: "SSW S413",
                  },
                  {
                    image: "ssw/19/tyres/S413-19x95_BKM_5H-02.webp",
                    alt: "SSW S413",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "19 inch", "Black Matt", "Size 19 x 8.5", "PCD 5 x 112", "Offset 35"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
        ],
      },
      {
        id: "6",
        name: "20 Inch",
        image: "ssw/20-Inch.webp",
        tyre_image: null,
        typeCount: 4,
        tyreType: [
          {
            id: "1",
            name: "S317-20",
            title: "S317",
            wheels: [
              {
                title: "S317J01",
                subTitle: null,
                description: null,
                slider: [
                   {
                     image: "ssw/20/tyres/S317J01_20x10_SI_01.webp",
                     alt: "SSW S317",
                   },
                   {
                     image: "ssw/20/tyres/S317J01_20x10_SI_02.webp",
                     alt: "SSW S317",
                   },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "20 inch", "Silver", "Size 20 x 8.5", "PCD 5 x 108", "Offset 35"],
                advanced_features: [],
                brochure: null,
              }
            ],
          },
          {
            id: "2",
            name: "S376-20",
            title: "S376",
            wheels: [
              {
                title: "S376J01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/20/tyres/S376-20x9_GMM_01.webp",
                    alt: "SSW S376",
                  },
                  {
                    image: "ssw/20/tyres/S376-20x9_GMM_02.webp",
                    alt: "SSW S376",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "20 inch", "Gun Metal Matt", "Size 20 x 9", "PCD 5 x 114.3", "Offset 42"],
                advanced_features: [],
                brochure: null,
              }
            ],
          },
          {
            id: "3",
            name: "S386-20",
            title: "S386",
            wheels: [
              {
                title: "S386J01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/20/tyres/S386-18x9_FP_BK_GCBK_C095-1.webp",
                    alt: "SSW S386",
                  },
                  {
                    image: "ssw/20/tyres/S386-18x9_FP_BK_GCBK_C095-2.webp",
                    alt: "SSW S386",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "20 inch", "Face Polish /Black /Gloss Clear Bronze", "Size 20 x 9", "PCD 6 x 139", "Offset 20"],
                advanced_features: [],
                brochure: null,
              }
            ],
          },
          {
            id: "4",
            name: "S411-20",
            title: "S411",
            wheels: [
              {
                title: "S411J01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/20/tyres/S411-20x9-FP_BK_6H.webp",
                    alt: "SSW S411",
                  },
                  {
                    image: "ssw/20/tyres/S411-20x9-FP_BK_6H2.webp",
                    alt: "SSW S411",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "20 inch", "Face Polish Black", "Size 20 x 9", "PCD 6 x 139", "Offset 20"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S411J02",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "ssw/20/tyres/S411-20x9-FP_BK_6H.webp",
                    alt: "SSW S411",
                  },
                  {
                    image: "ssw/20/tyres/S411-20x9-FP_BK_6H2.webp",
                    alt: "SSW S411",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Stamford Sport Wheel", "20 inch", "Face Polish Black", "Size 20 x 9", "PCD 6 x 114.3", "Offset 20"],
                advanced_features: [],
                brochure: null,
              }
            ],
          },
        ],
      },
    ],
    diesel: [
      {
        id: "1",
        name: "17 Inch",
        image: "diesel/17-Inch.webp",
        tyre_image: null,
        typeCount: 5,
        tyreType: [
          {
            id: "1",
            name: "S342-17",
            title: "S342",
            wheels: [
              {
                title: "S342G04",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/17/tyres/S342_17x9_BKM.webp",
                    alt: "Diesel S342",
                  },
                  {
                    image: "diesel/17/tyres/S342_17x9_BKM2.webp",
                    alt: "Diesel S342",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "17 inch", "Black Matt", "Size 17 x 9", "PCD 6 x 139.7", "Offset 0", "Centre bore 106.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "2",
            name: "S358-17",
            title: "S358",
            wheels: [
              {
                title: "S358G01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/17/tyres/S358 17x9_FAP_BKM_NM_1.webp",
                    alt: "Diesel S358",
                  },
                  {
                    image: "diesel/17/tyres/S358 17x9_FAP_BKM_NM_2.webp",
                    alt: "Diesel S358",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "17 inch", "Face Polish/ Black Matt/ Nut Milling", "Size 17 x 9", "PCD 6 x 139.7", "Offset 20", "Centre bore 110.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "3",
            name: "S375-17",
            title: "S375",
            wheels: [
              {
                title: "S375G01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/17/tyres/S375 17x9_BKM_6H_001.webp",
                    alt: "Diesel S375",
                  },
                  {
                    image: "diesel/17/tyres/S375 17x9_BKM_6H_002.webp",
                    alt: "Diesel S375",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "17 inch", "Black Matt/ Spoke Milling/ Lip Polish", "Size 17 x 9", "PCD 6 x 139.7", "Offset 0", "Centre bore 106.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "4",
            name: "S382-17",
            title: "S382",
            wheels: [
              {
                title: "S382G01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/17/tyres/S382 17x9_FAP_BKM_NM_MCGR_01.webp",
                    alt: "Diesel S382",
                  },
                  {
                    image: "diesel/17/tyres/S382 17x9_FAP_BKM_NM_MCGR_02.webp",
                    alt: "Diesel S382",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "17 inch", "Face Polish/ Black Matt/ Nut Milling/ Matt Clear Grey", "Size 17 x 9", "PCD 5 x 150", "Offset 0", "Centre bore 110.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "5",
            name: "S406-17",
            title: "S406",
            wheels: [
              {
                title: "S406G01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/17/tyres/S406-17x9_BKM-01.webp",
                    alt: "Diesel S406",
                  },
                  {
                    image: "diesel/17/tyres/S406-17x9_BKM-02.webp",
                    alt: "Diesel S406",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "17 inch", "Black Matt", "Size 17 x 9", "PCD 5 x 150", "Offset 0"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
        ],
      },
      {
        id: "2",
        name: "18 Inch",
        image: "diesel/18-Inch.webp",
        tyre_image: null,
        typeCount: 4,
        tyreType: [
          {
            id: "1",
            name: "S304-18",
            title: "S304",
            wheels: [
              {
                title: "S304H01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/18/tyres/S304 18x9_BKM_SM-01.webp",
                    alt: "Diesel S304",
                  },
                  {
                    image: "diesel/18/tyres/S304 18x9_BKM_SM-02.webp",
                    alt: "Diesel S304",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "18 inch", "Black Matt/ Spoke Milling", "Size 18 x 9", "PCD 6 x 139.7", "Offset -10", "Centre bore 106.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "2",
            name: "S341-18",
            title: "S341",
            wheels: [
              {
                title: "S341H01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/18/tyres/S341 18x9_BKM.webp",
                    alt: "Diesel S341",
                  },
                  {
                    image: "diesel/18/tyres/S341 18x9_BKM2.webp",
                    alt: "Diesel S341",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "18 inch", "Black Matt", "Size 18 x 9", "PCD 6 x 139.7", "Offset 20", "Centre bore 110.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "3",
            name: "S342-18",
            title: "S342",
            wheels: [
              {
                title: "S342H01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/18/tyres/S342_18x9_BKM.webp",
                    alt: "Diesel S342",
                  },
                  {
                    image: "diesel/18/tyres/S342_18x9_BKM2.webp",
                    alt: "Diesel S342",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "18 inch", "Black Matt", "Size 18 x 9", "PCD 6 x 139.7", "Offset 0", "Centre bore 106.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "4",
            name: "S392-18",
            title: "S392",
            wheels: [
              {
                title: "S392H01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/18/tyres/S392-18x9_BKM_6H_001.webp",
                    alt: "Diesel S392",
                  },
                  {
                    image: "diesel/18/tyres/S392-18x9_BKM_6H_002.webp",
                    alt: "Diesel S392",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "18 inch", "Black Matt", "Size 18 x 9", "PCD 5 x 150", "Offset 0"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
        ],
      },
      {
        id: "3",
        name: "20 Inch",
        image: "diesel/20-Inch.webp",
        tyre_image: null,
        typeCount: 6,
        tyreType: [
          {
            id: "1",
            name: "S265-20",
            title: "S265",
            wheels: [
              {
                title: "S265J01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/20/tyres/S265j03_20x9_FAP_BKM-DIESEL.webp",
                    alt: "Diesel S265",
                  },
                  {
                    image: "diesel/20/tyres/S265j03_20x9_FAP_BKM-DIESEL2.webp",
                    alt: "Diesel S265",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "20 inch", "Face Polish/ Black Matt", "Size 20 x 9", "PCD 6 x 139.7", "Offset 20", "Centre bore 110.1"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S265J02",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/20/tyres/S265 20X9_6X139_ET20_BKM_DIESEL1.webp",
                    alt: "Diesel S265",
                  },
                  {
                    image: "diesel/20/tyres/S265 20X9_6X139_ET20_BKM_DIESEL2.webp",
                    alt: "Diesel S265",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "20 inch", "Black Matt", "Size 20 x 9", "PCD 6 x 139.7", "Offset 20", "Centre bore 110.1"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S265J03",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/20/tyres/S265 20X9_6X139_ET20_BKM_DIESEL1.webp",
                    alt: "Diesel S265",
                  },
                  {
                    image: "diesel/20/tyres/S265 20X9_6X139_ET20_BKM_DIESEL2.webp",
                    alt: "Diesel S265",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "20 inch", "Black Matt", "Size 20 x 9", "PCD 6 x 139.7", "Offset 20", "Centre bore 106.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "2",
            name: "S304-20",
            title: "S304",
            wheels: [
              {
                title: "S304J01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/20/tyres/S304 20x9.5 BKM.webp",
                    alt: "Diesel S304",
                  },
                  {
                    image: "diesel/20/tyres/S304 20x9.5 BKM2.webp",
                    alt: "Diesel S304",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "20 inch", "Black Matt", "Size 20 x 9.5", "PCD 6 x 139.7", "Offset 20", "Centre bore 110.1"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S304J02",
                subTitle: null,
                description: null,
                slider: [
                   {
                     image: "diesel/20/tyres/S304J02_20x9.5_FP_BK_6H.webp",
                     alt: "Diesel S304",
                   },
                   {
                     image: "diesel/20/tyres/S304J03_20x9.5_FP_BK_6H2.webp",
                     alt: "Diesel S304",
                   },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "20 inch", "Face Polish/ Gloss Black", "Size 20 x 9.5", "PCD 6 x 139", "Offset 20"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S304J03",
                subTitle: null,
                description: null,
                slider: [
                   {
                     image: "diesel/20/tyres/S304J02_20x9.5_GMM_6H.webp",
                     alt: "Diesel S304",
                   },
                   {
                     image: "diesel/20/tyres/S304J03_20x9.5_GMM_6H2.webp",
                     alt: "Diesel S304",
                   },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "20 inch", "Gun Metal Matt", "Size 20 x 9.5", "PCD 6 x 139", "Offset 20"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          // {
          //   id: "3",
          //   name: "S319-20",
          //   title: "S319",
          //   wheels: [
          //     {
          //       title: "S319J01",
          //       subTitle: null,
          //       description: null,
          //       slider: [
          //         // {
          //         //   image: "diesel/20/tyres/",
          //         //   alt: "Diesel S319",
          //         // },
          //       ],
          //       partner: [],
          //       product_portfolo: [],
          //       product_portfolo_image: null,
          //       video: null,
          //       features: ["Diesel", "20 inch", "Black Matt/ Lip Polish", "Size 20 x 9", "PCD 6 x 139.7", "Offset 25", "Centre bore 110.1"],
          //       advanced_features: [],
          //       brochure: null,
          //     },
          //   ],
          // },
          {
            id: "4",
            name: "S341-20",
            title: "S341",
            wheels: [
              {
                title: "S341J01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/20/tyres/S341J02 20x9_FP_BK.webp",
                    alt: "Diesel S341",
                  },
                  {
                    image: "diesel/20/tyres/S341J02 20x9_FP_BK2.webp",
                    alt: "Diesel S341",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "20 inch", "Face Polish/ Black Matt", "Size 20 x 9", "PCD 6 x 139.7", "Offset 20", "Centre bore 110.1"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S341J02",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/20/tyres/S341J01 20x9_BKM.webp",
                    alt: "Diesel S341",
                  },
                  {
                    image: "diesel/20/tyres/S341J01 20x9_BKM2.webp",
                    alt: "Diesel S341",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "20 inch", "Black Matt", "Size 20 x 9", "PCD 6 x 139.7", "Offset 20", "Centre bore 110.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "5",
            name: "S357-20",
            title: "S357",
            wheels: [
              {
                title: "S357J01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/20/tyres/S357 20x95 BK-SM-NM_1.webp",
                    alt: "Diesel S357",
                  },
                  {
                    image: "diesel/20/tyres/S357 20x95 BK-SM-NM_2.webp",
                    alt: "Diesel S357",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "20 inch", "Black Matt/ Lip Polish/ Spoke Milling", "Size 20 x 9.5", "PCD 6 x 139.7", "Offset 35", "Centre bore 110.1"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S357J02",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/20/tyres/S357 20x95 BK-SM-NM_1.webp",
                    alt: "Diesel S357",
                  },
                  {
                    image: "diesel/20/tyres/S357 20x95 BK-SM-NM_2.webp",
                    alt: "Diesel S357",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "20 inch", "Black Matt/ Spoke Milling/ Nut Milling", "Size 20 x 9.5", "PCD 6 x 139.7", "Offset 0", "Centre bore 106.1"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S357J03",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/20/tyres/S357 20x95 BK-SM-NM_1.webp",
                    alt: "Diesel S357",
                  },
                  {
                    image: "diesel/20/tyres/S357 20x95 BK-SM-NM_2.webp",
                    alt: "Diesel S357",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "20 inch", "Black Matt/ Spoke Milling/ Nut Milling", "Size 20 x 9.5", "PCD 5 x 120", "Offset 0", "Centre bore 65.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
          {
            id: "6",
            name: "S375-20",
            title: "S375",
            wheels: [
              {
                title: "S375J01",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/20/tyres/S375_20x9.5_BKM_SM_LP.webp",
                    alt: "Diesel S375",
                  },
                  {
                    image: "diesel/20/tyres/S375_20x9.5_BKM_SM_LP2.webp",
                    alt: "Diesel S375",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "20 inch", "Black Matt/ Spoke Milling/ Lip Polish", "Size 20 x 9.5", "PCD 6 x 139.7", "Offset 35", "Centre bore 110.1"],
                advanced_features: [],
                brochure: null,
              },
              {
                title: "S375J02",
                subTitle: null,
                description: null,
                slider: [
                  {
                    image: "diesel/20/tyres/S375_20x9.5_BKM_SM_LP.webp",
                    alt: "Diesel S375",
                  },
                  {
                    image: "diesel/20/tyres/S375_20x9.5_BKM_SM_LP2.webp",
                    alt: "Diesel S375",
                  },
                ],
                partner: [],
                product_portfolo: [],
                product_portfolo_image: null,
                video: null,
                features: ["Diesel", "20 inch", "Black Matt/ Lip Polish/ Spoke Milling", "Size 20 x 9.5", "PCD 6 x 139.7", "Offset 0", "Centre bore 106.1"],
                advanced_features: [],
                brochure: null,
              },
            ],
          },
        ],
      },
    ],
  },
  downloads: [
    {
      id: "1",
      title: "Cert Russia 2018 to 2022",
      url: "/static/pdf/Cert Russia 2018 to 2022.pdf",
    },
    {
      id: "2",
      title: "Certificate Stamford 2021",
      url: "/static/pdf/certificate Stamford 2021.pdf",
    },
    {
      id: "3",
      title: "ISO 9001",
      url: "/static/img/ISO 9001.jpg",
    },
    {
      id: "4",
      title: "ISO TS16949",
      url: "/static/img/ISO TS16949.jpg",
    },
    {
      id: "5",
      title: "JWL Certificate Expiry 27_07_2025 New",
      url: "/static/pdf/JWL Certificate Expiry 27_07_2025 New.pdf",
    },
    {
      id: "6",
      title: "SNI Certificate 2018-2021",
      url: "/static/pdf/SNI Certificate 2018-2021.pdf",
    },
    {
      id: "7",
      title: "SSW Company profile",
      url: "/static/pdf/SSW Company profile.pdf",
    },
    {
      id: "8",
      title: "SSW warranty CARD (EN)",
      url: "/static/img/SSW warranty_CARD_EN.jpg",
    },
    // {
    //   id: "9",
    //   title: "SSW warranty CARD (TH)",
    //   url: "/static/img/SSW warranty_CARD_TH.jpg",
    // },
    {
      id: "10",
      title: "TUV Certificate ECE, KBA Confirmation Expiry 05_07_2021_1",
      url: "/static/pdf/TUV Certificate ECE , KBA Confirmation Expiry 05_07_2021_1.pdf",
    },
    // {
    //   id: "11",
    //   title: "Warranty CARD DIESEL",
    //   url: "/static/img/warranty_CARD_DIESEL.jpg",
    // },
    {
      id: "12",
      title: "Warranty CARD DIESEL (EN)",
      url: "/static/img/warranty_CARD_DIESEL_EN.jpg",
    },
  ],
};

export default config;
