import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Home from "@/views/ssw/HomeView.vue";
import SSWTryeRange from "@/views/ssw/SSWTryeRangeView.vue";
import DieselTryeRange from "@/views/ssw/DieselTryeRangeView.vue";
import Downloads from "@/views/ssw/DownloadsView.vue";
import PageNotFound from "@/views/ssw/PageNotFoundView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "ssw-home",
    component: Home,
  },
  {
    path: "/ssw-wheels",
    name: "ssw-wheels",
    component: SSWTryeRange,
  },
  {
    path: "/diesel-wheels",
    name: "diesel-wheels",
    component: DieselTryeRange,
  },
  {
    path: "/downloads",
    name: "downloads",
    component: Downloads,
  },
  {
    path: '/404',
    name: 'page-not-found',
    component: PageNotFound
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash }
    }

    if (from && Object.keys(to.query).length) {
      if (to.fullPath.split('?')[0] == from.fullPath.split('?')[0]) return;
    }

    return { top: 0, behavior: 'smooth' }; 
  }
});

router.afterEach(() => {
  const reCapthca = document.querySelector('.grecaptcha-badge') as any;
  if(router.currentRoute.value.name && (router.currentRoute.value.name !== 'ssw-home')) {
    if(reCapthca) {
      reCapthca.style.visibility = 'hidden';
    }
  } else {
    if(reCapthca) {
      reCapthca.style.visibility = `visible`;
    }
  }
});

export default router;
