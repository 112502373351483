
import { defineComponent } from "vue";
import Header from "@/components/ssw/Header/Header.vue";
import HomeBanner from "@/components/ssw/HomeBanner/HomeBanner.vue";
import TabsSection from "@/components/ssw/tyre-range/TabsSection/TabsSection.vue";

export default defineComponent({
  name: "DieselTryeRangeView",
  components: {
    Header,
    HomeBanner,
    TabsSection,
  },
});
