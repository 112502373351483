
import { defineComponent } from "vue";
import Footer from "@/components/Footer/Footer.vue";
import { IReCaptchaComposition, useReCaptcha } from "vue-recaptcha-v3";

export default defineComponent({
  components: {
    Footer
  },
  data() {
    let recapthcaObject : IReCaptchaComposition | any = undefined
    return {
      recapthcaObject
    }
  },
  computed: {
    routeCurrentGet () {
      return this.$route.name
    },
  },
  created() {
    this.recapthcaObject = useReCaptcha() as IReCaptchaComposition;
    this.hideRecaptcha();
  },
  methods: {
    async hideRecaptcha() {
      if (this.recapthcaObject) {
        // Wait until recaptcha has been loaded.
        await this.recapthcaObject.recaptchaLoaded();

        // Hide recaptcha has been loaded.
        const reCapthca = document.querySelector(".grecaptcha-badge") as any;
        if (reCapthca && (this.$route.name !== 'ssw-home')) {
          reCapthca.style.visibility = `hidden`;
        } else {
          reCapthca.style.visibility = `visible`;
        }
      }
    }
  }
});
