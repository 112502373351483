
import { defineComponent } from "vue";
import Header from "@/components/ssw/Header/Header.vue";
import HomeBanner from "@/components/ssw/HomeBanner/HomeBanner.vue";
import HomeStory from "@/components/ssw/HomeStory/HomeStory.vue";
import ProprietyBrand from "@/components/ssw/ProprietyBrand/ProprietyBrand.vue";
import SocialMedia from "@/components/ssw/SocialMedia/SocialMedia.vue";
import HomeContact from "@/components/ssw/HomeContact/HomeContact.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    Header,
    HomeBanner,
    HomeStory,
    ProprietyBrand,
    SocialMedia,
    HomeContact
  },
  created() {
    window.scrollTo(0, 0)
  },
});
