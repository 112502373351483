
import { defineComponent } from "vue";
import Header from "@/components/ssw/Header/Header.vue";
import DownloadsPage from "@/components/ssw/Downloads/Downloads.vue";

export default defineComponent({
  name: "DownloadsView",
  components: {
    Header,
    DownloadsPage,
  }
});
