import { defineComponent } from 'vue';
import EventBus from '@/EventBus';

export default defineComponent({
  name: 'social-media',
  mounted (){
    var element : any
    EventBus.on('my-ssw-home', (evt : any) => {
      if (evt.eventContent == 'sswContactUs') {
        element = document.getElementById("sswContactUs");
        var team = element.offsetTop;
        window.scrollTo(0, team);
      }
    })
  }
});