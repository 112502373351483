import { defineComponent } from 'vue';
import EventBus from '@/EventBus';
export default defineComponent({
  name: 'home-story',
  mounted (){
    var element : any
    EventBus.on('my-ssw-home', (evt : any) => {
      if (evt.eventContent == 'sswAboutUs') {
        element = document.getElementById("sswAboutUs");
        var team = element.offsetTop;
        window.scrollTo(0, team);
      }
    })
  }
});